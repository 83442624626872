import React, { useEffect, useState } from 'react';
import './home-stats.css';
import CitizensLogo from '../../../assets/home/home-stats/citizens.svg';
import LawslLogo from '../../../assets/home/home-stats/laws.svg';
import DealsLogo from '../../../assets/home/home-stats/deals.svg';
import VotingLogo from '../../../assets/home/home-stats/voting.svg';
import ServicesLogo from '../../../assets/home/home-stats/services.svg';
import CapitalLogo from '../../../assets/home/home-stats/capital.svg';
import StatsItems from './stats-items/stats-item';
import { Trans, Translation } from 'react-i18next';
import InfoModal from '../../info-modals/info-modals';
import OrnamentProfile from '../../ornament-profile/ornament-profile';
import i18n from '../../../i18n/i18n';
import { BSpan } from '../../../utils/text-utils/text-utils';
import { AppLoader } from '../../../utils/animations/animations';
import { FEDERAL_BLUE } from '../../../utils/color-palatte';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../routes/routes';
// import thousandSeparator from '../../../utils/thousandSeparator';

const HomeStats = ({ officialVotings, noOfficialVotings, users, laws, deals, positions, capital, loading }) => {
  const [firstMatches, setFirstMatches] = useState(window.matchMedia('(max-width: 590px)').matches);

  useEffect(() => {
    window.matchMedia('(max-width: 590px)').addEventListener('change', (e) => setFirstMatches(e.matches));
  });

  const history = useHistory();

  const StatElements = [
    {
      Logo: CitizensLogo,
      Txt: i18n.t('GENERAL_TEXT.citizens'),
      Num: users,
      style: { cursor: 'auto'}
    },
    {
      Logo: LawslLogo,
      Txt: i18n.t('GENERAL_TEXT.laws'),
      Num: laws,
    },
    {
      Logo: DealsLogo,
      Txt: i18n.t('GENERAL_TEXT.deals'),
      Num: deals,
    },
    {
      Logo: VotingLogo,
      Txt: i18n.t('GENERAL_TEXT.votings'),
      Num: noOfficialVotings + officialVotings,
    },
    {
      Logo: ServicesLogo,
      Txt: i18n.t('GENERAL_TEXT.services'),
      Num: 0,
    },
    {
      Logo: CapitalLogo,
      Txt: i18n.t('GENERAL_TEXT.capital'),
      Num: capital,
      mask: '$',
    },
  ];

  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
  });

  const OpenInfoModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  const president =
    positions && positions.length > 0
      ? positions.filter((item) => item.position === 'president').length > 0
        ? positions.filter((item) => item.position === 'president')[0].user
        : null
      : null;

  const vicepresident =
    positions && positions.length > 0
      ? positions.filter((item) => item.position === 'vicepresident').length > 0
        ? positions.filter((item) => item.position === 'vicepresident')[0].user
        : null
      : null;

  const OrnamentData = [
    {
      userPicture: president && president.picture ? president.picture : null,
      userName: president && president.username ? president.username : 'Position Avilable',
      userRank: 'President',
      onClick: () =>
        president?._id
          ? history.push(`${routes.LOGIN_ROUTE}?redirect=${routes.PROFILE_ROUTE.replace('/', '')}/${president._id}`)
          : history.push(`${routes.LOGIN_ROUTE}?redirect=${routes.GG_HIERARCHY_ROUTE.replace('/', '')}`),
    },
    {
      userPicture: vicepresident && vicepresident.picture ? vicepresident.picture : null,
      userName: vicepresident && vicepresident.username ? vicepresident.username : 'Position Avilable',
      userRank: 'Vicepresident',
      onClick: () => vicepresident?._id
      ? history.push(`${routes.LOGIN_ROUTE}?redirect=${routes.PROFILE_ROUTE.replace('/', '')}/${vicepresident._id}`)
      : history.push(`${routes.LOGIN_ROUTE}?redirect=${routes.GG_HIERARCHY_ROUTE.replace('/', '')}`),
    },
  ];
  return (
    <Translation>
      {(t) => (
        <div className='Home__Stats'>
          <h1>{t('HOME_STATS.TITLE')}</h1>
          {/* <div className='Home__Stats-divider' /> */}
          <p className='Home__Stats-p'>
            <Trans i18nKey='HOME_STATS.P'>
              bla
              <BSpan onClick={() => OpenInfoModal(true, 'laws')}> bla </BSpan>,
              <BSpan onClick={() => OpenInfoModal(true, 'deal')}> bla</BSpan>
              bla
              <BSpan onClick={() => OpenInfoModal(true, 'voting')}> bla</BSpan>
              bla
            </Trans>
          </p>
          {/* <div className='Home__Stats-leaderboard'>
            {loading ? (
              <AppLoader color={FEDERAL_BLUE} size='big' />
            ) : (
              OrnamentData.map((ornaments, i) => (
                <OrnamentProfile
                  style={{
                    transform: firstMatches ? 'scale(.8, .8)' : 'unset',
                    cursor: 'pointer'
                  }}
                  key={i}
                  {...ornaments}
                />
              ))
            )}
          </div> */}
          <section className='Cards-container'>
            {StatElements.map((Elements, n) => (
              <StatsItems
                key={n}
                {...Elements}
                onClick={() => n === 0 ? void(0) : history.push(`${routes.LOGIN_ROUTE}?redirect=${routes.GLOBAL_GOVERNMENT_ROUTE.replace('/', '')}`)}
              />
            ))}
          </section>
          <InfoModal isOpen={modal.isOpen} type={modal.type} onRequestClose={() => setModal({ ...modal, isOpen: false })} />
        </div>
      )}
    </Translation>
  );
};

export default HomeStats;

import axios from 'axios';
import { API_URL, TIME_TO_RESPONSE } from '../config';
import { decrypt } from '../utils/encrypt';
import i18n from '../i18n/i18n';
import { LOGOUT, OPEN_MESSAGE_MODAL } from '../redux/types/user/types';
import { store } from '../redux/configureStore';

const isEncryptionActive = true

const handleError = (e) => {
  let errorObj = {
    data: {
      error: {
        code: 'internal-error',
        details: '',
        message:
          e.response && e.response.status === 429
            ? i18n.t('GENERAL_ERRORS.tooManyRequests')
            : e.message === 'Network Error'
            ? i18n.t('GENERAL_ERRORS.noInternet')
            : i18n.t('GENERAL_ERRORS.serverError'),
      },
    },
  };

  const error =
    e.response && e.response.status === 429 ? errorObj : e.response || errorObj;
  return error;
};

const validateSession = (e) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState().user;
    let errorToShow = '';
    if (state) {
      // console.log("validateSession / e: ", e);
      const error =
        e && e.data && e.data.error && e.data.error.code
          ? e.data.error.code
          : '';
      const errorMessage =
        e && e.data && e.data.error && e.data.error.message
          ? e.data.error.message
          : i18n.t('ERRORS.serverError');

      if (error === 'token-expired' && e.status !== 429) {
        // console.log('validateSesionErrorMessage:', error);
        await store.dispatch({ type: LOGOUT, data: null });
        setTimeout(() => {
          store.dispatch({
            type: OPEN_MESSAGE_MODAL,
            data: { message: errorMessage, type: 'wrong' },
          });
        }, 2000);
        errorToShow = errorMessage;
      }

      resolve(errorToShow);
    } else {
      resolve(errorToShow);
    }
  });
};

const language =
  localStorage.getItem('language') === 'us'
    ? 'en'
    : localStorage.getItem('language') || 'en';

/**
 * POST fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */
const postData = (url, data, isAuth = true ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fullUrl = `${API_URL}${url}`;
      const token = localStorage.getItem('token');

      const response = await timeout(
        Number(TIME_TO_RESPONSE),
        axios.post(fullUrl, data, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            Accept: '*/*',
            'access-token': isAuth ? token : '',
          },
        })
      );
      // console.log("services / postData / response: : ", response)
      const decryptData = isEncryptionActive ? await decrypt(response.data) : response.data;
      // console.log('services / postData / decryptData:', decryptData);
      resolve(decryptData);
    } catch (e) {
      const error = handleError(e);
      // console.log('postData:', error)

      await validateSession(error);
      reject(error);
    }
  });
};

/**
 * PUT fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */

const putData = (url, data, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      // await checkConnection();
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${API_URL}${url}`;
      // console.log('services / putData / fullUrl:', fullUrl);
      // console.log('token:', token);
      const response = await timeout(
        Number(TIME_TO_RESPONSE),
        axios.put(fullUrl, data, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );
      const decryptData = isEncryptionActive ? await decrypt(response.data) : response.data;
      // console.log('services / putData / response:', decryptData);
      resolve(decryptData);
    } catch (e) {
      // console.log('services / putData / error:', e);
      const error = handleError(e);

      await validateSession(error);
      reject(error);
    }
  });
};

export const deleteData = (url, data, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      // await checkConnection();
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${API_URL}${url}`;
      // console.log('services / deleteData / fullUrl:', fullUrl);
      // console.log('token:', token);
      const response = await timeout(
        Number(TIME_TO_RESPONSE),
        axios.delete(fullUrl, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );
      const decryptData = isEncryptionActive ? await decrypt(response.data) : response.data;
      // console.log('services / deleteData / decryptData:', decryptData);
      resolve(decryptData);
    } catch (e) {
      // console.log('services / deleteData / error:', e);
      const error = handleError(e);

      await validateSession(error);
      reject(error);
    }
  });
};

/**
 * GET fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */
const getData = (url, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fullUrl = `${API_URL}${url}`;
      const token = localStorage.getItem('token');

      // console.log('getDataURL', fullUrl);
      const response = await timeout(
        Number(TIME_TO_RESPONSE),
        axios.get(fullUrl, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            Accept: '*/*',
            'access-token': isAuth ? token : '',
          },
        })
      );
      // console.log("services / getData / response: ", response);
      const decryptData = isEncryptionActive ? await decrypt(response.data) : response.data;
      // console.log('services / getData / decryptData:', decryptData);
      resolve(decryptData);
    } catch (e) {
      const error = handleError(e);
      // console.log('catch2', error)
      await validateSession(error);

      reject(error);
    }
  });
};

/**
 * Timeout for fetch request
 * @param  {number} ms milliseconds
 * @param  {promise} promise the fetch promise
 */
const timeout = (ms, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject({
        response: {
          data: {
            error: {
              code: 'internal-error',
              details: '',
              message: i18n.t('GENERAL_ERRORS.timeOut'),
            },
          },
        },
        body: '',
      });
    }, ms);
    promise.then(resolve, reject);
  });
};

export { postData, getData, putData };

import React from 'react';
import styles from './styles.module.css';
import AlertModal from '../../utils/modal/Modal';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../utils/app-inputs/password-input/password-input';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import * as colors from '../../utils/color-palatte';
import { sendVerificationCode, validateVerificationCode } from '../../pages/log-in/login-actions';
import CheckIcon from '../../assets/verification-modal/checked_icon.svg';
import Alert from '../../utils/alert/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from '../../routes/routes';
import { store } from '../../redux/configureStore';
import * as types from '../../redux/types/user/types';
import { connect } from 'react-redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { bindActionCreators } from 'redux';
import { AppLoader } from '../../utils/animations/animations';
import CloseBtn from '../../assets/payment-modals/close_btn.svg';

const VerificationModal = (props) => {
  const { useState, useEffect } = React;
  const { isOpen, onRequestClose, data, existPassword, user, onValidateFunc } = props;
  const [isVerified, setIsVerified] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const [values, setValues] = useState({
    password: '',
    code: '',
  });

  const [alert, setAlert] = useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  useEffect(() => {
    if (isOpen) {
      (async () => {
        await sendVerification();
      })();
    }
    return () => {
      setIsVerified(false)
      setIsFetching(false)
      setValues({
        password: '',
        code: ''
      })
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const sendVerification = async () => {
    return new Promise(async (resolve, _) => {
      if (!isFetching) {
        setIsFetching(true);
        try {
          const dataToSend = {
            userId: data ? data.body.user._id : user ? user._id : '',
          };
          // console.log('called');
          await sendVerificationCode(dataToSend);
          setIsFetching(false);
          resolve();
        } catch (e) {
          setIsFetching(false);
          // console.log(e);
        }
      }
    });
  };

  const validationCode = async () => {
    if (values.code && (existPassword || values.password)) {
      setIsFetching(true);
      return new Promise(async (resolve, reject) => {
        try {
          const dataSend = {
            code: values.code,
            password: existPassword ? existPassword : values.password,
          };
          await validateVerificationCode(dataSend);
          setIsFetching(false);
          setIsVerified(true);
          if (!user) {
            onLoginSucces(data);
            onRequestClose();
            resolve();
          }
          resolve();
        } catch (e) {
          const error = e?.data?.error?.details?.message?.toString() ?? t('GENERAL_ERRORS.serverError');
          showAlert(true, 'error', error);
          setIsFetching(false);
          reject();
        }
      });
    } else {
      if (!values.code) {
        showAlert(true, 'warning', t('GENERAL_ERRORS.missingCode'));
        return false;
      }
      if (!values.password && !existPassword) {
        showAlert(true, 'warning', t('GENERAL_ERRORS.missingPassword'));
        return false;
      }
    }
  };

  const onLoginSucces = (data) => {
    store.dispatch({ type: types.SAVE_USER_HAS_ENTERED, data: true });
    localStorage.setItem('token', data.body.token);
    localStorage.setItem('userId', data.body.user._id);
    props.saveUser(data.body.user);
    // this.stopLoader();
    // this.resetState();
    if (location.pathname !== routes.LOGIN_ROUTE) {
      history.push(`${location.pathname}`);
    } else {
      history.push(routes.DASHBOAR_ROUTE);
    }
  };

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const showAlert = (isOpen, type, content) => {
    setAlert({
      ...alert,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <AlertModal isOpen={isOpen} outsideClick={onRequestClose} overlayClassName={styles.Overlay} modalClassName={styles.Modal}>
      <img
        src={CloseBtn}
        alt='close'
        onClick={onRequestClose}
        style={{ position: 'absolute', right: 0, top: 0, marginRight: 10, marginTop: 10, width: '35px', cursor: 'pointer' }}
      />
      {!isVerified ? (
        <div className={styles.VerificationForm}>
          <h1 style={{ color: colors.WHITE_MAIN, marginBottom: 5 }}>{t('TWO_STEP_VAL.title')}</h1>
          <p style={{ color: colors.WARNING_YELLOW }}>{user ? t('TWO_STEP_VAL.content') : t('TWO_STEP_VAL.contentLogin')}</p>
          {!existPassword && (
            <PasswordInput
              name='password'
              value={values.password}
              onChange={handleInputChange}
              isWhite
              placeholder={t('TWO_STEP_VAL.INPUTS.password')}
              autoComplete='off'
            />
          )}
          <RegularInput
            name='code'
            value={values.code}
            onChange={handleInputChange}
            isWhite
            placeholder={t('TWO_STEP_VAL.INPUTS.code')}
            autoComplete='off'
          />
          {isFetching ? (
            <AppLoader color='#fff' size='50px' />
          ) : (
            <>
              <button className={styles.SendButton} onClick={validationCode} style={{ marginBottom: 5}}>
                {t('GENERAL_TEXT.continue')}
              </button>
              <button className={styles.SendButton} onClick={sendVerification} style={{ marginTop: 5}}>
                {t('TWO_STEP_VAL.button')}
              </button>
            </>
          )}
        </div>
      ) : (
        <div className={styles.VerificationForm}>
          <h1 className={styles.Title}>
            <img src={CheckIcon} alt='' />
            {t('TWO_STEP_VAL.VERIFIED.title')}
          </h1>
          <p style={{ color: colors.WHITE_MAIN, fontSize: '1.2rem' }}>{t('TWO_STEP_VAL.VERIFIED.content')}</p>
          <button className={styles.SendButton} onClick={() => (!user ? onLoginSucces(data) : onValidateFunc())}>
            {t('GENERAL_TEXT.continue')}
          </button>
        </div>
      )}
      <Alert
        isOpen={alert.isOpen}
        type={alert.type}
        content={alert.content}
        onRequestClose={() => setAlert({ ...alert, isOpen: false, type: null, content: undefined })}
      />
    </AlertModal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDarkMode: bindActionCreators(actions.toggleDarkMode, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    logOut: bindActionCreators(actions.logOut, dispatch),
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode, isAuthenticationEnabled } = state;
  return {
    user,
    darkMode,
    isAuthenticationEnabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal);

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import ChangePassword from '../pages/change-password/ChangePassword';
import DownloadApp from '../components/download-app/DownloadApp';
import { FEDERAL_BLUE_STRONG, UNSATURATED_BLACK_BLUE } from '../utils/color-palatte';
// import UnderConstruction from "./components/under-construction/UnderConstruction";
import SucessMail from '../pages/sucess-mail/sucess-mail';
import MailError from '../pages/mail-error/mail-error';
import PasswordDone from '../pages/password-done/password-done';
import Faq from '../pages/faq/faq';
import ScrollToTop from '../utils/ScrollToTop';
import Login from '../pages/log-in/log-in';
import SignUp from '../pages/sign-up/sign-up';
import RestorePassword from '../pages/restore-password/restore-password';
import Welcome from '../pages/welcome/welcome';
import Layout from '../components/layout/layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../redux/actions/user/actions';
import Dashboard from '../pages/dashboard/dashboard';
import Profile from '../pages/profile/Profile';
import Settings from '../pages/settings/Settings';
import * as routes from './routes';
import Voting from '../pages/voting/voting';
import CreateVote from '../pages/create-vote/createVote';
import Followers from '../pages/followers/Followers';
import history from '../routes/history';
import Alert from '../utils/alert/Alert';
import PostComments from '../pages/post-comments/postComments';
import PostDetails from '../pages/post-details/postDetails';
import CreatePost from '../pages/create-post/createPost';
import NoticeOfPrivacy from '../pages/notice-of-privacy/noticeOfPrivacy';
import TermsAndConditions from '../pages/terms-and-conditions/termsAndConditions';
import NewPaymentModal from '../components/payment-modals/new-payment-modal/NPAModal';
import UserChats from '../pages/messages/user-chats/userChats';
import UserChat from '../pages/messages/user-chat/userChat';
import VotingList from '../pages/voting-list/votingList';
// import Search from '../pages/search/search';
import Communities from '../pages/communities/communities';
import Services from '../pages/services/services';
import GlobalGovernment from '../pages/global-government/globalGovernment';
import Following from '../pages/following/Following';
import UserFinance from '../pages/user-finance/UserFinance';
import UserPayments from '../pages/user-payments/UserPayments';
import PaymentDetails from '../pages/payment-details/PaymentDetails';
import VotingHistory from '../pages/voting-history/VotingHistory';
import Hierarchy from '../pages/hierarchy/hierarchy';
// import SubAdviceModal from '../components/payment-modals/sub-advice/subAdviceModal';
// import PaymentWarnnigModal from '../components/payment-modals/payment-warning/paymentWarningModal';
// import PaymentMethodModal from '../components/payment-modals/payment-method-modal/paymentMethodModal';
// import PaymentReceivedModal from '../components/payment-modals/payment-received/paymentReceivedModal';
// import AddCreditCardModal from '../components/payment-modals/add-credit-card-modal/addCreditCardModal';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Laws from '../pages/laws/laws';
import Deals from '../pages/deals/deals';
import DealDetails from '../pages/deals-details/dealsDetails';
import LawsDetails from '../pages/laws-details/lawsDetails';
import Finances from '../pages/finances/finances';
import CommunityProfile from '../pages/communities-profile/communityProfile';
import Flow from '../components/home/flow/flow';
import GGIncomesList from '../pages/incomes/ggIncomesList';
import GGExpensesList from '../pages/expenses/ggExpensesList';
import GGIncomeDetail from '../pages/income-delails/ggIncomeDetail';
import GGExpenseDetail from '../pages/expense-detail/ggExpenseDetail';
import CommunityPosts from '../pages/community-posts/communityPosts';
import CommunityMembers from '../pages/community-members/communityMembers';
import UserAchievements from '../pages/user-achievements/userAchievements';
import CreateCommunity from '../pages/create-community/createCommunity';
import NewAchievementModal from '../components/achievement-modal/achievementModal';
import UserNotifications from '../pages/notifications/userNotifications';
import PositionHistory from '../pages/postition-history/positionHistory';
import Whitepaper from '../pages/whitepaper/whitepaper';
import Privacy from '../pages/privacy/privacy';
import DeleteSuccessModal from '../components/privacy/deleteSuccess';
import Security from '../pages/security/security';
import DeleteAccount from '../pages/delete-account/deleteAccount';
import VerifyRequestModal from '../components/verify-request-modal/verifyRequestModal';
import DialogModal from '../utils/dialog-modal/dialogModal';
import * as config from '../config';
import * as colors from '../utils/color-palatte';
import { useTranslation } from 'react-i18next';
import SelfXss from '../pages/self-xss/selfXss';

const Navigator = ({
  user,
  messageModalType,
  messageModalMessage,
  isMessageModalOpen,
  closeMessageModal,
  isPaymentModalAgreementOpen = false,
  darkMode,
  closePaymentModalAgreement,
  isNewAchievementOpen,
  newAchievement,
  userHasEntered,
  isDeleteSuccessModalOpen,
  closeDeleteSuccesModal,
  isVerificationModalOpen,
  closeVerifyModal,
  isDialogOpen,
  dialogType,
  dialogMessage,
  dialogLoading,
  dialogYes,
  dialogNo,
  closeDialogModal,
}) => {
  // const notify = (content) => toast(content);
  // const [notification, setNotification] = useState({ title: '', body: '' });

  // onMessageListener().then(payload => {
  //   console.log("onMessageListener / payload: ", payload)
  //   setNotification({ title: payload.notification.title, body: payload.notification.body })
  //   notify(payload.notification.title)
  //   // console.log(payload);
  // }).catch(err => console.log('failed: ', err));
  // useEffect(() => {
  // }, [])
  // const [isOpen, setIsOpen] = React.useState(true);
  // const [isOpen2, setIsOpen2] = useState(true);
  // console.log("user: ", user)

  // React.useEffect(() => {
  //   store.dispatch({ type: OPEN_DELETE_SUCCESS_MODAL, data: null })
  // }, [])

  const { t } = useTranslation();
  /**
   * the color scheme is used to get the UI theme of navigator,
   * I use this to change the color of border text in the console,
   * because the Browser's UI can be Black or white
   */
  const [scheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  /**
   * this state is for print the text just once, but if the user reload's the page
   * the state is come back to be false and print's the message again...
   */
  const [isPrinted, setIsPrinted] = React.useState(false);

  const printMsgFunc = () => {
    if (!isPrinted) {
      setIsPrinted(true);
      // clear the outputs for production
      console.clear();
      console.log(
        `%c${t('WARNING_MSG.title')}`,
        `font-size: 3rem; color: ${
          colors.FEDERAL_BLUE
        }; font-family: Source Sans Pro, sans-serif; font-weight: bold; -webkit-text-stroke: .5px ${
          scheme ? '#fff' : '#000'
        }; text-stroke: .5px ${scheme ? '#fff' : '#000'};`
      );
      console.log(`%c${t('WARNING_MSG.message')}`, 'font-size: 1.5rem; font-family: Source Sans Pro, sans-serif;');
      console.log(
        `%c${t('WARNING_MSG.moreInfo')(`${window.location.origin}/self-xss`)}`,
        'font-size: 1.5rem; font-family: Source Sans Pro, sans-serif;'
      );
    }
  };
  React.useEffect(() => {
    if (!config.NODE_ENV || config.NODE_ENV === 'development') {
      // prints for development
    } else {
      if (user) {
        if (!isPrinted) {
          printMsgFunc();
        }
      }
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <Router history={history}>
      {/* <div style={{ background: FEDERAL_BLUE_STRONG }}>
      <Header />
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Layout
        style={{
          backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : FEDERAL_BLUE_STRONG,
        }}
      >
        <ScrollToTop />
        {user && user !== null && userHasEntered ? (
          <Switch>
            <Route path={routes.DASHBOAR_ROUTE} component={Dashboard} />
            <Route path={routes.HOME_ROUTE} component={Dashboard} />
            <Route path={`${routes.COMMENTS_ROUTE}/:post`} component={PostComments} />
            <Route path={`${routes.USER_FOLLOWERS_ROUTE}/:userId`} component={Followers} />
            <Route path={`${routes.USER_FOLLOWING_ROUTE}/:userId`} component={Following} />
            <Route path={`${routes.POST_ROUTE}/:post`} component={PostDetails} />
            <Route path={`${routes.USER_PAYMENT_DETAILS}/:payment`} component={PaymentDetails} />
            <Route path={`${routes.VOTING_DETAILS_ROUTE}/:votingId`} component={VotingList} />
            <Route path={`${routes.PROFILE_ROUTE}/:userId`} component={Profile} />
            <Route path={`${routes.USER_CHAT}/:chat`} component={UserChat} />
            <Route path={`${routes.VOTING_HISTORY}/:userId`} component={VotingHistory} />
            <Route path={`${routes.LAWS_LIST_ROUTE}/:userId`} component={Laws} />
            <Route path={`${routes.LAWS_DETAILS_ROUTE}/:lawId`} component={LawsDetails} />
            <Route path={`${routes.DEALS_LIST_ROUTE}/:userId`} component={Deals} />
            <Route path={`${routes.DEALS_DETAILS_ROUTE}/:dealId`} component={DealDetails} />
            <Route path={`${routes.COMMUNITY_PROFILE_ROUTE}/:communityId`} component={CommunityProfile} />
            <Route path={`${routes.COMMUNITY_MEMBERS_ROUTE}/:communityId`} component={CommunityMembers} />
            <Route path={`${routes.COMMUNITY_POSTS_ROUTE}/:communityId`} component={CommunityPosts} />
            <Route path={`${routes.GG_INCOMES_DETAILS_ROUTE}/:incomeId`} component={GGIncomeDetail} />
            <Route path={`${routes.GG_EXPENSES_DETAILS_ROUTE}/:expenseId`} component={GGExpenseDetail} />
            <Route path={`${routes.USER_ACHIEVEMENTS_ROUTE}/:userId`} component={UserAchievements} />
            <Route path={routes.USER_FOLLOWERS_ROUTE} component={Followers} />
            <Route path={routes.USER_FOLLOWING_ROUTE} component={Following} />
            <Route path={routes.CREATE_VOTE_ROUTE} component={CreateVote} />
            <Route path={routes.USER_FINANCE_ROUTE} component={UserFinance} />
            <Route path={routes.USER_PATMENTS_ROUTE} component={UserPayments} />
            <Route path={routes.USER_PAYMENT_DETAILS} component={PaymentDetails} />
            <Route path={routes.VOTINGS_ROUTE} component={Voting} />
            <Route path={routes.VOTING_LIST_ROUTE} component={VotingList} />
            <Route path={routes.PROFILE_ROUTE} component={Profile} />
            <Route path={routes.SETTINGS_ROUTE} component={Settings} />
            <Route path={routes.PRYVACY_ROUTE} component={Privacy} />
            <Route path={routes.SECURITY_ROUTE} component={Security} />
            <Route path={routes.FAQ_ROUTE} component={Faq} />
            <Route path={routes.NOTICE_OF_PRIVACY_ROUTE} component={NoticeOfPrivacy} />
            <Route path={routes.FLOW_ROUTE} children={<Flow isScreen darkMode={darkMode} />} />
            <Route path={routes.TERMS_AND_CONDITIONS_ROUTE} component={TermsAndConditions} />
            <Route path={routes.MESSAGES_ROUTE} component={UserChats} />
            <Route path={routes.WELCOME_ROUTE} component={Welcome} />
            <Route path={routes.VOTING_HISTORY} component={VotingHistory} />
            <Route path={routes.CREATE_POST_ROUTE} component={CreatePost} />
            <Route path={routes.LAWS_LIST_ROUTE} component={Laws} />
            <Route path={routes.DEALS_LIST_ROUTE} component={Deals} />
            <Route exact path={routes.ROOT_ROUTE} component={Dashboard} />
            <Route path={routes.SERVICES_ROUTE} component={Services} />
            <Route path={routes.SEARCH_ROUTE} component={Dashboard} />
            <Route path={routes.GLOBAL_GOVERNMENT_ROUTE} component={GlobalGovernment} />
            <Route path={routes.COMMUNITIES_ROUTE} component={Communities} />
            <Route path={routes.COMMUNITY_CREATE_ROUTE} component={CreateCommunity} />
            <Route path={routes.GG_HIERARCHY_ROUTE} component={Hierarchy} />
            <Route path={routes.GG_FINANCE_ROUTE} component={Finances} />
            <Route path={routes.GG_INCOMES_LIST_ROUTE} component={GGIncomesList} />
            <Route path={routes.GG_EXPENSES_LIST_ROUTE} component={GGExpensesList} />
            <Route path={routes.USER_ACHIEVEMENTS_ROUTE} component={UserAchievements} />
            <Route path={routes.USER_NOTIFICATIONS_ROUTE} component={UserNotifications} />
            <Route path={routes.GG_POSITIONS_HISTORY} component={PositionHistory} />
            <Route path={routes.WHITEPAPER_ROUTE} component={Whitepaper} />
            <Route path={routes.DELETE_YOU_ACCOUNT} component={DeleteAccount} />
            <Route path={routes.SELF_XSS_ROUTE} component={SelfXss} />
            <Route path='*' component={Dashboard} />
          </Switch>
        ) : (
          <Switch>
            <Route path={`${routes.USER_PAYMENT_DETAILS}/:payment`} component={Login} />
            <Route path={routes.USER_PAYMENT_DETAILS} component={Login} />
            <Route path={routes.HOME_ROUTE} component={Home} />
            <Route path={routes.CHANGE_PASSWORD_ROUTE} component={ChangePassword} />
            <Route path={routes.PASSWORD_DONE_ROUTE} component={PasswordDone} />
            <Route path={routes.POST_ROUTE} component={Login} />
            <Route path={routes.VOTINGS_ROUTE} component={Login} />
            <Route path={routes.LAWS_LIST_ROUTE} component={Login} />
            <Route path={routes.FAQ_ROUTE} component={Faq} />
            <Route path={routes.TERMS_AND_CONDITIONS_ROUTE} component={TermsAndConditions} />
            <Route path={routes.DEALS_LIST_ROUTE} component={Login} />
            <Route path={routes.INCOMES_ROUTE} component={Login} />
            <Route path={routes.EXPENSES_ROUTE} component={Login} />
            <Route path={routes.COMMUNITIES_ROUTE} component={Login} />
            <Route path={routes.DOWNLOAD_APP_ROUTE} component={DownloadApp} />
            <Route path={routes.SUCCES_MAIL_ROUTE} component={SucessMail} />
            <Route path={routes.MAIL_ERROR_ROUTE} component={MailError} />
            <Route path={routes.USER_PATMENTS_ROUTE} component={Login} />
            <Route path={`${routes.USER_PATMENTS_ROUTE}/:paymentId`} component={Login} />
            <Route path={routes.LOGIN_ROUTE} component={Login} />
            <Route path={routes.SIGN_UP_ROUTE} component={SignUp} />
            <Route path={routes.RESTORE_PASSWORD_ROUTE} component={RestorePassword} />
            <Route path={routes.NOTICE_OF_PRIVACY_ROUTE} component={NoticeOfPrivacy} />
            <Route path={routes.WHITEPAPER_ROUTE} children={<Whitepaper outer />} />
            <Route exact path={routes.ROOT_ROUTE} component={Home} />
            <Route path={`${routes.COMMENTS_ROUTE}/:post`} component={Login} />
            <Route path={`${routes.USER_FOLLOWERS_ROUTE}/:userId`} component={Login} />
            <Route path={`${routes.USER_FOLLOWING_ROUTE}/:userId`} component={Login} />
            <Route path={`${routes.POST_ROUTE}/:post`} component={Login} />
            <Route path={`${routes.USER_PAYMENT_DETAILS}/:payment`} component={Login} />
            <Route path={`${routes.VOTING_DETAILS_ROUTE}/:votingId`} component={Login} />
            <Route path={`${routes.PROFILE_ROUTE}/:userId`} component={Login} />
            <Route path={`${routes.USER_CHAT}/:chat`} component={Login} />
            <Route path={`${routes.VOTING_HISTORY}/:userId`} component={Login} />
            <Route path={`${routes.LAWS_LIST_ROUTE}/:userId`} component={Login} />
            <Route path={`${routes.LAWS_DETAILS_ROUTE}/:lawId`} component={Login} />
            <Route path={`${routes.DEALS_LIST_ROUTE}/:userId`} component={Login} />
            <Route path={`${routes.DEALS_DETAILS_ROUTE}/:dealId`} component={Login} />
            <Route path={`${routes.COMMUNITY_PROFILE_ROUTE}/:communityId`} component={Login} />
            <Route path={`${routes.COMMUNITY_MEMBERS_ROUTE}/:communityId`} component={Login} />
            <Route path={`${routes.COMMUNITY_POSTS_ROUTE}/:communityId`} component={Login} />
            <Route path={`${routes.GG_INCOMES_DETAILS_ROUTE}/:incomeId`} component={Login} />
            <Route path={`${routes.GG_EXPENSES_DETAILS_ROUTE}/:expenseId`} component={Login} />
            <Route path={`${routes.USER_ACHIEVEMENTS_ROUTE}/:userId`} component={Login} />
            <Route path={routes.USER_FOLLOWERS_ROUTE} component={Login} />
            <Route path={routes.USER_FOLLOWING_ROUTE} component={Login} />
            <Route path={routes.CREATE_VOTE_ROUTE} component={Login} />
            <Route path={routes.USER_FINANCE_ROUTE} component={Login} />
            <Route path={routes.VOTING_LIST_ROUTE} component={Login} />
            <Route path={routes.PROFILE_ROUTE} component={Login} />
            <Route path={routes.SETTINGS_ROUTE} component={Login} />
            <Route path={routes.FLOW_ROUTE} component={Login} />
            <Route path={routes.MESSAGES_ROUTE} component={Login} />
            <Route path={routes.VOTING_HISTORY} component={Login} />
            <Route path={routes.CREATE_POST_ROUTE} component={Login} />
            <Route path={routes.SERVICES_ROUTE} component={Login} />
            <Route path={routes.SEARCH_ROUTE} component={Login} />
            <Route path={routes.GLOBAL_GOVERNMENT_ROUTE} component={Login} />
            <Route path={routes.COMMUNITIES_ROUTE} component={Login} />
            <Route path={routes.COMMUNITY_CREATE_ROUTE} component={Login} />
            <Route path={routes.GG_HIERARCHY_ROUTE} component={Login} />
            <Route path={routes.GG_FINANCE_ROUTE} component={Login} />
            <Route path={routes.GG_INCOMES_LIST_ROUTE} component={Login} />
            <Route path={routes.GG_EXPENSES_LIST_ROUTE} component={Login} />
            <Route path={routes.USER_ACHIEVEMENTS_ROUTE} component={Login} />
            <Route path={routes.USER_NOTIFICATIONS_ROUTE} component={Login} />
            <Route path={routes.PRYVACY_ROUTE} component={Login} />
            <Route path={routes.GG_POSITIONS_HISTORY} component={Login} />
            <Route path={routes.SELF_XSS_ROUTE} component={SelfXss} />
            <Route path={routes.DELETE_YOU_ACCOUNT} component={DeleteAccount} />
            <Route path='*' component={Home} />
          </Switch>
        )}
        {/* <Footer />
        <MiniFooter />
      </div> */}
        <Alert
          type={messageModalType}
          isOpen={isMessageModalOpen}
          content={messageModalMessage}
          onRequestClose={() => closeMessageModal()}
        />
        <NewPaymentModal isOpen={isPaymentModalAgreementOpen} onRequestClose={() => closePaymentModalAgreement()} />
        <NewAchievementModal isOpen={isNewAchievementOpen} newAchievement={newAchievement} user={user} darkMode={darkMode} />
        <DeleteSuccessModal isOpen={isDeleteSuccessModalOpen} onRequestClose={() => closeDeleteSuccesModal()} />
        <VerifyRequestModal isOpen={isVerificationModalOpen} user={user} onRequestClose={() => closeVerifyModal()} />
        <DialogModal
          isOpen={isDialogOpen}
          type={dialogType}
          content={dialogMessage}
          yes={dialogYes}
          no={dialogNo}
          loading={dialogLoading}
          onRequestClose={() => closeDialogModal()}
        />
      </Layout>
    </Router>
  );
};

// stage changes

const mapDispatchToProps = (dispatch) => {
  return {
    closeMessageModal: bindActionCreators(actions.closeMessageModal, dispatch),
    closePaymentModalAgreement: bindActionCreators(actions.closePaymentModalAgreement, dispatch),
    closeDeleteSuccesModal: bindActionCreators(actions.closeDeleteSuccesModal, dispatch),
    closeVerifyModal: bindActionCreators(actions.closeVerifyModal, dispatch),
    closeDialogModal: bindActionCreators(actions.closeDialogModal, dispatch),
  };
};

const mapStateToProps = (state) => {
  const {
    user,
    messageModalMessage,
    messageModalType,
    isMessageModalOpen,
    darkMode,
    isPaymentModalAgreementOpen,
    userHasEntered,
    isDeleteSuccessModalOpen,
    isVerificationModalOpen,
    isDialogOpen,
    dialogType,
    dialogMessage,
    dialogLoading,
    dialogYes,
    dialogNo,
  } = state;
  const { isNewAchievementOpen, newAchievement } = state.achievements;
  return {
    user,
    messageModalType,
    messageModalMessage,
    isMessageModalOpen,
    darkMode,
    isPaymentModalAgreementOpen,
    isNewAchievementOpen,
    newAchievement,
    userHasEntered,
    isDeleteSuccessModalOpen,
    isVerificationModalOpen,
    isDialogOpen,
    dialogType,
    dialogMessage,
    dialogLoading,
    dialogYes,
    dialogNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);

import React from 'react';
import './home.css';
// import ReactGlobe from "react-globe";
import { postData } from '../../fetch';
// import { FEDERAL_WHITE } from '../../utils/color-palatte';
// import Button from '../../utils/Button';
// import { Translation } from 'react-i18next';
import Banner from '../../components/home/banner/banner';
import HomeVideo from '../../components/home/home-video/home-video';
import HomeStats from '../../components/home/home-stats/home-stats';
import InfluencerCarousel from '../../components/home/influencer-carousel/influencer-carousel';
import Benefits from '../../components/home/benefits/benefits';
import Flow from '../../components/home/flow/flow';
import HomeCarousel from '../../components/home/home-carousel/home-carousel';
import HomeDownload from '../../components/home/home-download/home-download';
import getStat from '../../utils/getStat';
import Alert from '../../utils/alert/Alert';
import i18n from '../../i18n/i18n';
import InfoModal from '../../components/info-modals/info-modals';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as routes from '../../routes/routes';
import HomeRoadmap from '../../components/home/home-roadmap/HomeRoadmap';
import Reliability from '../../components/home/reliability/reliability';

class Home extends React.Component {
  state = {
    markers: [],
    officialVotings: null,
    noOfficialVotings: null,
    users: null,
    laws: null,
    deals: null,
    positions: [],
    capital: null,
    progress: 0,
  };

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) {
      const { user } = this.props;
      const url_string = window.location.href;
      const url = new URL(url_string);
      const token = url.searchParams.get('token');
      if (token) {
        this.confirmEmail();
      }
      if (user) {
        this.props.history.push(routes.DASHBOAR_ROUTE);
      }
    }

    if (this._isMounted) this.getStats();
    // window.addEventListener('scroll', this.listenToScroll)
  };
  /* componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      theposition: scrolled,
    })
    console.log(
      `theposition: ${this.state.theposition}
      winScroll: ${winScroll}
      height: ${height}
      `
    )
  } */

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const positions = await getStat('positions');
          const laws = await getStat('laws');
          const users = await getStat('users');
          const deals = await getStat('deals');
          const capital = await getStat('capital');
          const officialVotings = await getStat('official-votings');
          const noOfficialVotings = await getStat('no-official-votings');
          const maintenanceTaxes = await getStat('maintenance-taxes');
          const presidentSalaryPorcentage = await getStat('president-salary-porcentage');
          const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
          const citizenGainFromDeal = await getStat('citizen-gain-from-deal');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            officialVotings: Number(officialVotings) || 0,
            noOfficialVotings: Number(noOfficialVotings) || 0,
            users: Number(users) || 0,
            laws: Number(laws) || 0,
            deals: Number(deals) || 0,
            positions: positions,
            loading: false,
            // refresh: false,
            capital: Number(capital) || 0,
            maintenanceTaxes: maintenanceTaxes || 0,
            presidentSalaryPorcentage: presidentSalaryPorcentage || 0,
            vicepresidentSalaryPorcentage: vicepresidentSalaryPorcentage || 0,
            citizenGainFromDeal: citizenGainFromDeal || 0,
          });
          resolve();
        } catch (e) {
          reject();
          // console.log("getStats / error: ", e);
        }
      });
    });
  };

  confirmEmail = async () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get('token');
    const config = {
      onUploadProgress: (progressEvent) => {
        let { progress } = this.state;
        progress = (progressEvent.loaded / progressEvent.total) * 100;
        this.setState({ progress });
      },
    };
    // console.log("confirmEmail / token: ", token);
    this.showAlert(true, i18n.t('verifyingMessage'), 'email', true);
    try {
      // const { progress } = this.state;
      const response = await postData('/user/confirm-email', { token }, config);
      // console.log("confirmEmail / response: ", response);
      // alert(response.body);
      this.showAlert(true, response.body, 'success', false);
    } catch (e) {
      // console.log("confirmEmail / error: ", e);
      const error = e && e.data.error && e.data.error.message ? e.data.error.message : i18n.t('connectionErrorMessage');
      this.showAlert(true, error, 'error', false);
      // alert(error);
    }
  };

  showAlert = (isOpen, content, type, progress, choose) => {
    this.setState({
      isAlertOpen: isOpen,
      alertContent: content,
      alertType: type,
      isLoading: progress,
      isChoose: choose,
    });
  };

  showInfo = (isOpen, type) => {
    this.setState({
      isInfoOpen: isOpen,
      infoType: type,
    });
  };

  render() {
    //const { markers } = this.state;
    /*return (
      <Translation>
        {t =>
          <div className="container">
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ height: '65vh' }}>
                <ReactGlobe
                  globeOptions={{
                    enableBackground: false,
                    backgroundTexture: `https://raw.githubusercontent.com/chrisrzhou/react-globe/main/textures/background.png`,
                    cloudsTexture: `https://raw.githubusercontent.com/chrisrzhou/react-globe/main/textures/clouds.png`,
                    texture: `https://raw.githubusercontent.com/chrisrzhou/react-globe/main/textures/globe.jpg`,
                  }}
                  markers={markers}
                  enableTooltip={false}
                  markerOptions={{
                    enableTooltip: true,
                    getTooltipContent: () => t("citizens"),
                  }}
                />
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                noValidate
                className="home-form-container"
              >
                <div className="buttonsContainer">
              <h3 style={{ color: FEDERAL_WHITE }}>{t("what-are-you-wating")}</h3>
                  <Button
                    loading={false}
                    width="200px"
                    height="43px"
                    type="submit"
                    content={t("join-now")}
                  />
                </div>
              </form>
            </div>
            <div style={{ height: 500, width: '100%' }}>

        </div>
          </div>
        }
      </Translation>
    );*/
    // console.log("state: ", this.state);

    return (
      <div className='container'>
        <Banner />
        <HomeVideo />
        <HomeStats {...this.state} />
        <InfluencerCarousel />
        <Benefits />
        <Flow />
        <HomeCarousel />
        <Reliability
          maintenanceTaxes={this.state.maintenanceTaxes}
          presidentSalaryPorcentage={this.state.presidentSalaryPorcentage}
          vicepresidentSalaryPorcentage={this.state.vicepresidentSalaryPorcentage}
          citizenGainFromDeal={this.state.citizenGainFromDeal}
        />
        {/* <HomeRoadmap /> */}
        <HomeDownload />
        <Alert
          isOpen={this.state.isAlertOpen}
          content={this.state.alertContent}
          type={this.state.alertType}
          progress={this.state.isLoading}
          choose={this.state.isChoose}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
        <InfoModal
          isOpen={this.state.isInfoOpen}
          type={this.state.infoType}
          onRequestClose={() =>
            this.setState({
              isInfoOpen: false,
            })
          }
        />
      </div>
    );
  }
}

const HomeWithRouter = withRouter(Home);

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeWithRouter);

import { getData } from '../fetch';

const getStat = (name, exact = true, user = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await getData(
      //   `/globalgovernment/get-government-stats-web/?name=${name}&exact=${exact}&user=${user}`
      // );
      // // console.log("getStat / response: ", response)
      // const body = response.body;
      // return resolve(body);
      if (name === "deals") {
        return resolve(0);
      }
      if (name === "laws") {
        return resolve(0);
      }
      if (name === "users") {
        return resolve(0);
      }
      if (name === "official-votings") {
        return resolve(0);
      }
      if (name === "no-official-votings") {
        return resolve(0);
      }
      if (name === "capital") {
        return resolve(0);
      }
      if (name === "total-incomes") {
        return resolve(0);
      }
      if (name === "total-expenses") {
        return resolve(0);
      }
      if (name === "positions") {
        // console.log("entrose")
          return resolve();
      }
      if (name === "image-max-size-mb") {
        return resolve(5);
      }
      if (name === "video-max-size-mb") {
        return resolve(10);
      }
      if (name === "maintenance-taxes") {
        return resolve(39);
      }
      if (name === "notice-of-privicy-url") {
        return resolve("");
      }
      if (name === "position-expires-period-days") {
        return resolve(7776000 / 86400);
      }
      if (name === "voting-expires-period-days") {
        return resolve(259200 / 86400);
      }
      if (name === "president-salary-porcentage") {
        return resolve(0.70);
      }
      if (name === "vicepresident-salary-porcentage") {
        return resolve(0.30);
      }
      if (name === "vicepresident-salary") {
        return resolve(20);
      }
      if (name === "president-salary") {
        return resolve(40);
      }
      if (name === "payment-access-days") {
        return resolve(30);
      }
      if (name === "min-voting-assistance") {
        return resolve(50);
      }
      if (name === "time-to-approve-voting") {
        return resolve(24);
      }
      if (name === "contribution-every-payment-time") {
        return resolve(23);
      }
      if (name === "time-to-approve-deal-days") {
        return resolve(45);
      }
      if (name === "citizen-gain-from-deal") {
        return resolve(5);
      }
      if (name === "post-reports-limit") {
        return resolve(100);
      }
      if (name === "subscription-billing-amount") {
        return resolve(1);
      }
      if (name === "subscription-billing-amount-ios") {
        return resolve(1);
      }
      if (name === "subscription-billing-taxes") {
        return resolve(0.36);
      }
      if (name === "subscription-billing-taxes-ios") {
        return resolve(0.36);
      }
      if (name === "contribution-amount") {
        return resolve(2);
      }
      if (name === "contribution-amount-ios") {
        return resolve(2);
      }
      if (name === "contribution-billing-taxes") {
        return resolve(0.39);
      }
      if (name === "contribution-billing-taxes-ios") {
        return resolve(0.39);
      }
    } catch (e) {
      return reject(e);
    }
  });
};

export default getStat;

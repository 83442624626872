import React, { useEffect, useState } from 'react';
import { FEDERAL_WHITE, FEDERAL_RED, FEDERAL_GREEN, GRAY_MAIN } from '../../utils/color-palatte';
import Logo from '../../assets/header/logo.png';
import LogoAnim from '../../assets/header/flagAnim2.gif';
import GoBack from '../../assets/header/back_boton.svg';
import MembersLogo from '../../assets/header/icon_members.svg';
import Message from '../../assets/header/icon_message.svg';
import NotificationIcon from '../../assets/header/notification_btn.svg';
import MenuIcon from '../../assets/header/menu_icon_user.svg';
import './header.css';
import { Translation } from 'react-i18next';
import SafeLINK from '../../utils/safe-link';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import MenuLogo from '../../assets/header/menu_btn.svg';
import MenuLogoGray from '../../assets/header/menu_btn_gray.svg';
import MenuLogoBlue from '../../assets/header/menu_btn_blue.svg';
// import FaqLogo from '../../assets/header/icon_faq.svg';
import votinghistoryIcon from '../../assets/voting/icon_history.svg';
import * as routes from '../../routes/routes';
import SideMenu from '../layout/sideMenu';
import { WhitepaperMenu } from '../whitepaper/layout/Layout';
import { UserName, UserProfile } from '../ornament-profile/profileUtils';
import { mobileRegex1, mobileRegex2 } from '../../utils/mobileRegex';
import { DownloadButton } from '../home/home-download/home-download';

const HeaderBtns = (props) => {
  const { link, style, src, items, counter, firstMatches, secondMatches, thirdMatches } = props;
  return (
    <SafeLINK
      className='Header__User-Btn'
      dangerousLINK={link}
      style={Object.assign(
        {
          margin: firstMatches ? (secondMatches ? (thirdMatches ? '0 3px' : '0 3.5px') : '0 4px') : '0 5px',
        },
        style
      )}
    >
      <img src={src} className='Header__Msg' alt='' />
      {items && (
        <div
          style={{
            backgroundColor: FEDERAL_RED,
            position: 'absolute',
            width: `${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px`,
            height: `${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px`,
            borderRadius: `calc(${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px / 2`,
            top: 0,
            right: 0,
          }}
        />
      )}
      {counter && (
        <p
          style={{
            backgroundColor: FEDERAL_RED,
            position: 'absolute',
            minWidth: `${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px`,
            height: `${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px`,
            borderRadius: `calc(${firstMatches ? (secondMatches ? (thirdMatches ? 10 : 14) : 16) : 18}px / 2`,
            top: 0,
            right: 0,
            margin: 0,
            marginRight: -5,
            color: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '2px 5px',
            fontSize: `${firstMatches ? (secondMatches ? (thirdMatches ? 6.5 : 7) : 7.5) : 8}rem`,
          }}
        >
          {counter}
        </p>
      )}
    </SafeLINK>
  );
};

const Header = ({ user, darkMode, hasNewNotifications, userHasEntered, chatUser, foreignUser }) => {
  const location = useLocation();

  const path = location.pathname;

  const [firstMatches, setFirstMatches] = useState(window.matchMedia('(max-width: 840px)').matches);

  const [secondMatches, setSecondMatches] = useState(window.matchMedia('(max-width: 800px)').matches);

  const [mobileMatches, setMobileMatches] = useState(window.matchMedia('(max-width: 650px)').matches);

  const [isMobile, setIsMobile] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isDropped, setIsDropped] = useState(false);
  const [isThereUnreadMessages, setIsThereUnreadMessages] = useState('');
  const [isChat, setIsChat] = useState(false);

  useEffect(() => {
    window.matchMedia('(max-width: 840px)').addEventListener('change', (e) => setFirstMatches(e.matches));
    window.matchMedia('(max-width: 800px)').addEventListener('change', (e) => setSecondMatches(e.matches));
    window.matchMedia('(max-width: 650px)').addEventListener('change', (e) => setMobileMatches(e.matches));
    // console.log("header user", user);
  });

  var xDown = null;
  var yDown = null;

  useEffect(() => {
    if (mobileRegex1.test(navigator.userAgent) || mobileRegex2.test(navigator.userAgent.substr(0, 4))) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (secondMatches) {
      window.addEventListener('touchstart', handleTouchStart, false);
      window.addEventListener('touchmove', handleTouchMove, false);
    }
    return () => {
      if (secondMatches) {
        window.removeEventListener('touchstart', handleTouchStart, false);
        window.removeEventListener('touchmove', handleTouchMove, false);
      }
    };
    // eslint-disable-next-line
  }, [xDown, yDown, secondMatches]);

  function getTouches(evt) {
    return evt.touches; // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff < 0) {
        // console.log('left');
        setIsOpen(true);
      } else {
        // console.log('right');
        setIsOpen(false);
        // window.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowRight' }));
      }
    }
    // else {
    //   if (yDiff > 0) {
    //     console.log('up');
    //     window.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowUp' }));
    //   } else {
    //     console.log('down');
    //     window.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown' }));
    //   }
    // }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  useEffect(() => {
    (async () => {
      const isThereUnreadMessages = localStorage.getItem('isThereUnreadMessages');
      setIsThereUnreadMessages(isThereUnreadMessages);
    })();
    // eslint-disable-next-line
  }, []);

  const [animate, setAnimate] = useState(false);

  const history = useHistory();

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(routes.DASHBOAR_ROUTE);
    }
  };

  useEffect(() => {
    if (chatUser) {
      setIsChat(true);
    } else {
      setIsChat(false);
    }
  }, [chatUser, isChat]);

  useEffect(() => {}, [foreignUser]);

  const goUserProfile = () => {
    if (!chatUser) return;
    if (chatUser._id === user._id) {
      history.push(routes.PROFILE_ROUTE);
    } else {
      history.push(`${routes.PROFILE_ROUTE}/${chatUser._id}`);
    }
  };

  if (user && userHasEntered) {
    return (
      <Translation>
        {(t) => (
          <div className='User__Header-container'>
            {!secondMatches && (
              <>
                <SafeLINK
                  dangerousLINK={routes.GLOBAL_GOVERNMENT_ROUTE}
                  text={
                    <>
                      {animate && <img src={LogoAnim} className='Header__logo2' alt='logo' />}
                      {!animate && <img src={Logo} className='Header__logo' alt='logo' />}
                    </>
                  }
                  onMouseEnter={() => setAnimate(true)}
                  onMouseLeave={() => setAnimate(false)}
                />
                {!firstMatches && (
                  <SafeLINK
                    dangerousLINK={routes.GLOBAL_GOVERNMENT_ROUTE}
                    text={<h1 style={{ color: FEDERAL_WHITE, cursor: 'pointer' }}>Global Government</h1>}
                  />
                )}
              </>
            )}
            {secondMatches && (
              <div className='Header__Menu-btn' onClick={() => setIsOpen(!isOpen)}>
                <img src={MenuIcon} alt='' />
              </div>
            )}
            <div className='Header__User-buttons'>
              <button
                type='button'
                className='Header__User-Btn'
                style={{
                  display:
                    path === routes.HOME_ROUTE || path === routes.DASHBOAR_ROUTE || path === routes.ROOT_ROUTE
                      ? 'none'
                      : undefined,
                }}
              >
                <img src={GoBack} alt='' className='Header__GoBack' onClick={goBack} />
              </button>
              <h1
                className='Header__Route-title'
                style={{
                  fontSize: `${firstMatches ? (secondMatches ? (mobileMatches ? 1 : 1.3) : 1.5) : 1.8}rem`,
                  margin: `0 ${secondMatches ? 3 : 5}px`,
                }}
              >
                {path === routes.ROOT_ROUTE || path === routes.HOME_ROUTE || path === routes.DASHBOAR_ROUTE
                  ? t('DASHBOARD.home')
                  : path === routes.SEARCH_ROUTE
                  ? t('DASHBOARD.search')
                  : path === routes.FLOW_ROUTE
                  ? t('FLOW.title')
                  : path === routes.VOTINGS_ROUTE
                  ? t('DASHBOARD.votes')
                  : path === routes.SERVICES_ROUTE
                  ? t('DASHBOARD.services')
                  : path === routes.COMMUNITIES_ROUTE
                  ? t('DASHBOARD.communities')
                  : path.split('/').slice(0, -1).join('/') === routes.COMMUNITY_PROFILE_ROUTE
                  ? t('DASHBOARD.communities')
                  : path === routes.PROFILE_ROUTE
                  ? t('DASHBOARD.profile')
                  : path === routes.USER_FOLLOWING_ROUTE
                  ? `${user.username} ${t('DASHBOARD.following')}`
                  : path === routes.USER_FOLLOWERS_ROUTE
                  ? `${user.username} ${t('DASHBOARD.followers')}`
                  : path === routes.LAWS_LIST_ROUTE
                  ? window?.history?.state?.state?.user
                    ? `${user.username} ${t('LAWS.lawsTitle')}`
                    : t('LAWS.lawsTitle')
                  : path === routes.DEALS_LIST_ROUTE
                  ? window?.history?.state?.state?.user
                    ? `${user.username} ${t('DEALS.title')}`
                    : t('DEALS.title')
                  : path === routes.VOTING_HISTORY
                  ? window?.history?.state?.state?.user
                    ? `${user.username} ${t('DEALS.title')}`
                    : t('VOTING_LIST.votingHistory')
                  : path === routes.USER_PATMENTS_ROUTE
                  ? t('PAYMENTS_LIST.title')
                  : path.split('/').slice(0, -1).join('/') === routes.MESSAGES_ROUTE
                  ? t('DASHBOARD.messages')
                  : path === routes.MESSAGES_ROUTE
                  ? t('DASHBOARD.messages')
                  : path === routes.FAQ_ROUTE
                  ? 'FAQ'
                  : path === routes.VOTING_LIST_ROUTE ? t('LAWS.voting')
                  : path === routes.CREATE_POST_ROUTE
                  ? t('DASHBOARD.createPost')
                  : path === routes.SETTINGS_ROUTE
                  ? t('DASHBOARD.settingsTitle')
                  : path.split('/').slice(0, -1).join('/') === routes.POST_ROUTE
                  ? t('DASHBOARD.postDetails')
                  : path.split('/').slice(0, -1).join('/') === routes.COMMENTS_ROUTE
                  ? t('DASHBOARD.comments')
                  : path === routes.NOTICE_OF_PRIVACY_ROUTE
                  ? t('DASHBOARD.privacyPolicy')
                  : path === routes.TERMS_AND_CONDITIONS_ROUTE
                  ? t('DASHBOARD.terms')
                  : path === routes.USER_NOTIFICATIONS_ROUTE
                  ? t('DASHBOARD.notifications')
                  : path === routes.PRYVACY_ROUTE
                  ? t('PRIVACY.title')
                  : path === routes.SECURITY_ROUTE
                  ? t('SECURITY.title')
                  : path === routes.USER_ACHIEVEMENTS_ROUTE
                  ? `${user.username} ${t('ACHIEVEMENTS.title')}`
                  : path.split('/').slice(0, 2).join('/') === routes.USER_FINANCE_ROUTE
                  ? t('USER_FINANCE.title')
                  : path === routes.GG_FINANCE_ROUTE
                  ? t('USER_FINANCE.title')
                  : undefined}
                {foreignUser &&
                  (path !== routes.PROFILE_ROUTE && path.split('/').slice(0, -1).join('/') === routes.PROFILE_ROUTE
                    ? foreignUser.username
                    : path !== routes.USER_FOLLOWERS_ROUTE &&
                      path.split('/').slice(0, -1).join('/') === routes.USER_FOLLOWERS_ROUTE
                    ? `${foreignUser.username} ${t('DASHBOARD.followers')}`
                    : path !== routes.USER_FOLLOWING_ROUTE &&
                      path.split('/').slice(0, -1).join('/') === routes.USER_FOLLOWING_ROUTE
                    ? `${foreignUser.username} ${t('DASHBOARD.following')}`
                    : path !== routes.USER_ACHIEVEMENTS_ROUTE &&
                      path.split('/').slice(0, -1).join('/') === routes.USER_ACHIEVEMENTS_ROUTE
                    ? `${foreignUser.username} ${t('ACHIEVEMENTS.title')}`
                    : path !== routes.LAWS_LIST_ROUTE && path.split('/').slice(0, -1).join('/') === routes.LAWS_LIST_ROUTE
                    ? `${foreignUser.username} ${t('LAWS.lawsTitle')}`
                    : path !== routes.VOTING_HISTORY && path.split('/').slice(0, -1).join('/') === routes.VOTING_HISTORY
                    ? `${foreignUser.username} ${t('LAWS.voting')}`
                    : path !== routes.DEALS_LIST_ROUTE && path.split('/').slice(0, -1).join('/') === routes.DEALS_LIST_ROUTE
                    ? `${foreignUser.username} ${t('DEALS.title')}`
                    : null)}
              </h1>
              {chatUser && path.split('/').slice(0, 2).join('/') === routes.USER_CHAT ? (
                <>
                  <UserProfile
                    uri={chatUser.picture}
                    borderColor='#fff'
                    containerStyle={{ width: '40px', height: '40px' }}
                    onClick={goUserProfile}
                    showActive
                    dotStyle={{ width: '13px', height: '13px' }}
                    dotColor={chatUser.active ? FEDERAL_GREEN : GRAY_MAIN}
                  />
                  <UserName
                    username={chatUser.username}
                    isVerified={chatUser.isVerifiedUser}
                    style={{ marginLeft: mobileMatches ? '5px' : '10px', marginRight: mobileMatches ? '5px' : '10px' }}
                    titleStyles={{ color: '#fff' }}
                    onClick={goUserProfile}
                  />
                </>
              ) : null}
              {path === routes.GG_HIERARCHY_ROUTE && (
                <button src={MembersLogo} onClick={() => history.push(routes.GG_POSITIONS_HISTORY)} className='Header__User-Btn'>
                  <img src={votinghistoryIcon} className='Header__IMG' alt='' />
                </button>
              )}
              {path === routes.COMMUNITIES_ROUTE && (
                <button
                  src={MembersLogo}
                  onClick={() =>
                    user?.community?._id
                      ? history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${user.community._id}`)
                      : history.push(routes.COMMUNITY_CREATE_ROUTE)
                  }
                  className='Header__User-Btn'
                >
                  <img src={MembersLogo} className='Header__IMG' alt='' />
                </button>
              )}
              <div className='Header__User-buttons-divider' />
              <HeaderBtns
                // text={<img src={NotificationIcon} className='Header__Msg' alt='' />}
                // className='Header__User-Btn'
                items={hasNewNotifications}
                src={NotificationIcon}
                link={routes.USER_NOTIFICATIONS_ROUTE}
                style={{
                  display: path === routes.USER_NOTIFICATIONS_ROUTE ? 'none' : 'flex',
                }}
                firstMatches={firstMatches}
                secondMatches={secondMatches}
                thirdMatches={mobileMatches}
              />
              <HeaderBtns
                counter={isThereUnreadMessages}
                src={Message}
                link={routes.MESSAGES_ROUTE}
                style={{
                  display:
                    path === routes.MESSAGES_ROUTE || path.split('/').slice(0, -1).join('/') === routes.MESSAGES_ROUTE
                      ? 'none'
                      : 'flex',
                }}
                firstMatches={firstMatches}
                secondMatches={secondMatches}
                thirdMatches={mobileMatches}
              />
              {/* {!secondMatches && (
                <SafeLINK
                  dangerousLINK={routes.FAQ_ROUTE}
                  className='user-help-center'
                  text={t('HEADER.helpCenter')}
                  style={path === routes.FAQ_ROUTE ? { display: 'none' } : undefined}
                />
              )}
              {secondMatches && (
                <SafeLINK
                  dangerousLINK={routes.FAQ_ROUTE}
                  className='Header__Faq-button'
                  text={<img src={FaqLogo} alt='' />}
                  style={path === routes.FAQ_ROUTE ? { display: 'none' } : undefined}
                />
              )} */}
            </div>
            {path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE || path === routes.WHITEPAPER_ROUTE ? (
              <WhitepaperMenu
                isOpen={isOpen}
                darkMode={darkMode}
                onRequestClose={() => setIsOpen(false)}
                mobile
                uri={routes.WHITEPAPER_ROUTE}
              />
            ) : (
              <SideMenu mobile isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
            )}
          </div>
        )}
      </Translation>
    );
  }

  // console.log(path !== routes.DOWNLOAD_APP_ROUTE);

  return (
    <Translation>
      {(t) => (
        <div
          className='Header__Container'
          style={
            path === routes.DOWNLOAD_APP_ROUTE ||
            path.split('/').slice(0, 2).join('/') === routes.WHITEPAPER_ROUTE ||
            path === routes.TERMS_AND_CONDITIONS_ROUTE ||
            path === routes.NOTICE_OF_PRIVACY_ROUTE ||
            path === routes.SELF_XSS_ROUTE
              ? { position: 'relative' }
              : { position: 'absolute', backgroundColor: 'transparent' }
          }
        >
          {secondMatches &&
          (path === routes.WHITEPAPER_ROUTE || path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE) ? null : (
            <>
              <SafeLINK
                dangerousLINK={routes.HOME_ROUTE}
                text={
                  <>
                    {animate && <img src={LogoAnim} className='logo2' alt='logo' />}
                    {!animate && <img src={Logo} className='logo' alt='logo' />}
                  </>
                }
                onMouseEnter={() => setAnimate(true)}
                onMouseLeave={() => setAnimate(false)}
                onClick={() => setIsDropped(false)}
              />
              <SafeLINK
                dangerousLINK={routes.HOME_ROUTE}
                text={
                  <h1 className='Header__Title' style={{ color: FEDERAL_WHITE, cursor: 'pointer' }}>
                    Global Government
                  </h1>
                }
                onClick={() => setIsDropped(false)}
              />
            </>
          )}
          {path === routes.WHITEPAPER_ROUTE || path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE
            ? secondMatches && (
                <div className='Header__Menu-btn' onClick={() => setIsOpen(!isOpen)}>
                  <img src={MenuIcon} alt='' />
                </div>
              )
            : null}
          {firstMatches && (
            <img
              src={
                isDropped ? (path === routes.ROOT_ROUTE || path === routes.HOME_ROUTE ? MenuLogoBlue : MenuLogoGray) : MenuLogo
              }
              className='header__menu-img'
              onClick={() => setIsDropped(!isDropped)}
              alt=''
            />
          )}
          <div
            className='header__buttons'
            style={Object.assign({
              height: isDropped
                ? path === routes.LOGIN_ROUTE ||
                  path === routes.FAQ_ROUTE ||
                  path === routes.SIGN_UP_ROUTE ||
                  path.split('/').slice(0, 2).join('/') === routes.WHITEPAPER_ROUTE
                  ? '285px'
                  : isMobile
                  ? '335px'
                  : '215px'
                : '0',
            })}
          >
            <SafeLINK
              dangerousLINK={routes.FAQ_ROUTE}
              className='help-center'
              text={t('HEADER.helpCenter')}
              style={path === routes.FAQ_ROUTE ? { display: 'none' } : undefined}
              onClick={() => setIsDropped(false)}
            />
            <SafeLINK
              dangerousLINK={routes.LOGIN_ROUTE}
              className='log-in'
              text={t('HEADER.login')}
              style={path === routes.LOGIN_ROUTE ? { display: 'none' } : undefined}
              onClick={() => setIsDropped(false)}
            />
            <SafeLINK
              dangerousLINK={routes.SIGN_UP_ROUTE}
              className='sign-in'
              text={t('HEADER.signUp')}
              style={path === routes.SIGN_UP_ROUTE ? { display: 'none' } : undefined}
              onClick={() => setIsDropped(false)}
            />
            {isMobile ? (
              <>
                <SafeLINK
                  dangerousLINK={routes.WHITEPAPER_ROUTE}
                  className='sign-in'
                  text='Whitepaper'
                  style={path.split('/').slice(0, 2).join('/') === routes.WHITEPAPER_ROUTE ? { display: 'none' } : undefined}
                  onClick={() => setIsDropped(false)}
                />
                <DownloadButton type='android' imgStyle={{ width: '180px' }} style={{ height: 53, marginTop: 10 }} />
                <DownloadButton type='ios' imgStyle={{ width: '180px' }} style={{ height: 53, marginTop: 10 }} />
              </>
            ) : null}
          </div>
          {/* <div className="flags-select-container">
            <ReactFlagsSelect
              // ref={this.userFlag}
              defaultCountry={String(localStorage.getItem("language") || "US").toUpperCase()}
              countries={["US", "ES"]}
              showSelectedLabel={false}
              showOptionLabel={false}
              onSelect={(selected) => {
                localStorage.setItem("language", String(selected).toLowerCase())
                window.location.reload(false);
              }}
            />
          </div> */}
          {path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE || path === routes.WHITEPAPER_ROUTE ? (
            <WhitepaperMenu
              isOpen={isOpen}
              darkMode={darkMode}
              onRequestClose={() => setIsOpen(false)}
              mobile
              uri={routes.WHITEPAPER_ROUTE}
            />
          ) : null}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => {
  const { user, darkMode, hasNewNotifications, userHasEntered } = state;
  const { chatUser, foreignUser } = state.utils;
  return {
    user,
    darkMode,
    hasNewNotifications,
    userHasEntered,
    chatUser,
    foreignUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
